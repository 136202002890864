<template>
	<div id="wrap" class="loginWrap v2">
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2>아이디 찾기</h2>
				<a class="btnBack" @click="login()">이전 페이지</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- container -->
		<div id="container">
			<!-- contents -->
			<div class="contents">
				<!-- contCenter -->
				<div class="contCenter" v-if="!find">
					<!-- inputList -->
					<div class="inputList">
						<dl>
							<dt>닉네임</dt>
							<dd>
								<input
									type="text"
									title="닉네임 입력"
									placeholder="닉네임을 입력해주세요."
									required="required"
									v-model="nickname"
									@keyup.enter="findBtn ? onFindId() : null"
									maxlength="20"
									ref="nickname"
								/>
							</dd>
						</dl>
					</div>
					<!--// inputList -->
					<!-- btnArea -->
					<div class="btnArea">
						<button type="button" class="btn" @click="onFindId()" :disabled="!findBtn">아이디 찾기</button>
					</div>
					<!--// btnArea -->
				</div>
				<!--// contCenter -->
				<!-- contCenter -->
				<div class="contCenter" v-if="find">
					<!-- completeBox -->
					<div class="completeBox">
						<span>가입하신 아이디입니다.</span>
						<strong>{{ loginId }}</strong>
					</div>
					<!--// completeBox -->
					<!-- btnArea -->
					<div class="btnArea">
						<button type="button" class="btn" @click="login()">로그인</button>
					</div>
					<!--// btnArea -->
				</div>
			</div>
			<!--// contents -->
		</div>
		<!--// container -->
	</div>
</template>
<script>
import users from '@/api/user';
export default {
	data() {
		return {
			nickname: '',
			loginId: '',
			find: false,
		};
	},
	mounted() {
		this.$refs.nickname.focus();
	},
	computed: {
		findBtn: {
			get() {
				return this.nickname.length > 0;
			},
			set() {},
		},
	},
	methods: {
		async onFindId() {
			try {
				let res = await users.find(this.nickname);
				this.loginId = res.loginId;
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
			this.find = true;
		},
		login() {
			this.$router.push({ name: 'login' });
		},
	},
};
</script>
