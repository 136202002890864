// import axios from 'axios';
import axiosApi from '@/api/index';

let urlPath = '/api/users';

function get(id) {
	return axiosApi.get(`${urlPath}/${id}`);
}

async function save(user) {
	// return axiosApi.post(`${urlPath}`, user, { headers: { 'Content-Type': 'multipart/form-data' } });
	return axiosApi.post(`${urlPath}`, user);
}

async function update(user) {
	return axiosApi.put(`${urlPath}`, user);
}

async function duplicateNickname(nickname) {
	let params = {
		nickname: nickname,
	};
	return axiosApi.post(`${urlPath}/duplicateNickname`, params);
}

async function duplicateLoginId(loginId) {
	let params = {
		loginId: loginId,
	};
	return axiosApi.post(`${urlPath}/duplicateLoginId`, params);
}

async function find(nickname) {
	let params = {
		nickname: nickname,
	};
	return axiosApi.post(`${urlPath}/find`, params);
}

async function find2(loginId, nickname) {
	let params = {
		loginId: loginId,
		nickname: nickname,
	};
	return axiosApi.post(`${urlPath}/find2`, params);
}

async function updatePassword(id, password, password2) {
	let params = {
		id: id,
		password: password,
		password2: password2,
	};
	return axiosApi.put(`${urlPath}/updatePassword`, params);
}

async function resign(resignReason) {
	let params = {
		resignReason: resignReason,
	};
	return axiosApi.post(`${urlPath}/resign`, params);
}

async function resignWithdraw(loginId, password) {
	let params = {
		loginId: loginId,
		password: password,
	};
	return axiosApi.post(`${urlPath}/resignWithdraw`, params);
}

export default {
	get,
	save,
	update,
	duplicateNickname,
	duplicateLoginId,
	find,
	find2,
	updatePassword,
	resign,
	resignWithdraw,
};
